import React from 'react';

export default function FeatureCheat() {
    return (
        <div >
            <h3 className = "langCng" lang="en">Cheating</h3>
            <p className = "langCng" lang="en">Tap a specific place to display the front or back freely.</p>

            <h3 className="langCng" lang="ja">いかさま</h3>
            <p className="langCng" lang="ja">特定の場所をタップすると、表か裏をを自由に表示することができます</p>

            <h3 className = "langCng" lang="ru">Мошенничество</h3>
            <p className = "langCng" lang="ru">Коснитесь определенного места, чтобы свободно видеть переднюю или заднюю часть.</p>

            <h3 className = "langCng" lang="fr">Tricherie</h3>
            <p className = "langCng" lang="fr">Appuyez sur un emplacement spécifique pour voir le devant ou le dos librement.</p>

            <h3 className = "langCng" lang="de">Schummeln</h3>
            <p className = "langCng" lang="de">Tippen Sie auf eine bestimmte Stelle, um die Vorder- oder Rückseite frei zu sehen.</p>

            <h3 className = "langCng" lang="it">Imbrogliare</h3>
            <p className = "langCng" lang="it">Tocca una posizione specifica per vedere liberamente la parte anteriore o posteriore.</p>

            <h3 className = "langCng" lang="zh">作弊</h3>
            <p className = "langCng" lang="zh">点击特定位置可自由查看正面或背面。</p>

            <h3 className = "langCng" lang="ko">사기</h3>
            <p className = "langCng" lang="ko">특정 위치를 탭하면 앞이나 뒤를 자유롭게 볼 수 있습니다.</p>

            <h3 className = "langCng" lang="fi">Pettäminen</h3>
            <p className = "langCng" lang="fi">Napauta tiettyä sijaintia nähdäksesi etu- tai takaosa vapaasti.</p>

            <h3 className = "langCng" lang="es">Infiel</h3>
            <p className = "langCng" lang="es">Toque una ubicación específica para ver la parte delantera o trasera libremente.</p>

            <h3 className = "langCng" lang="tr">Hile</h3>
            <p className = "langCng" lang="tr">Önünü veya arkasını özgürce görmek için belirli bir konuma dokunun.</p>

            <h3 className = "langCng" lang="ms">Menipu</h3>
            <p className = "langCng" lang="ms">Ketik lokasi tertentu untuk melihat bahagian depan atau belakang dengan bebas.</p>

            <h3 className = "langCng" lang="ta">ஏமாற்றுதல்</h3>
            <p className = "langCng" lang="ta">முன் அல்லது பின்பகுதியை சுதந்திரமாகப் பார்க்க, குறிப்பிட்ட இடத்தைத் தட்டவும்.</p>

            <h3 className = "langCng" lang="id">selingkuh</h3>
            <p className = "langCng" lang="id">Ketuk lokasi tertentu untuk melihat bagian depan atau belakang dengan bebas.</p>

            <h3 className = "langCng" lang="pt">Trapaça</h3>
            <p className = "langCng" lang="pt">Toque em um local específico para ver a frente ou o verso livremente.</p>

            <h3 className = "langCng" lang="hi">धोखा</h3>
            <p className = "langCng" lang="hi">आगे या पीछे स्वतंत्र रूप से देखने के लिए किसी विशिष्ट स्थान पर टैप करें।</p>

            <h3 className = "langCng" lang="ar">الغش</h3>
            <p className = "langCng" lang="ar">आगे या पीछे स्वतंत्र रूप से देखने के लिए किसी विशिष्ट स्थान पर टैप करें।</p>

        </div>
    );
  }