import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Layout from '../components/Layout';
import Scroll from '../components/Scroll';
import Footer from '../components/Footer';
import Social from '../components/Social';
import demo1 from '../assets/images/demo-screen-1.jpg';
import gplay from '../assets/images/google-play-badge.svg';
import astore from '../assets/images/app-store-badge.svg';
import { Link } from "gatsby";
import HowtouseText from '../components/otherlanguage/HowtouseText';
import ContactText from '../components/otherlanguage/ContactText';
import IndexHeader from '../components/header/IndexHeader';
import FightText from '../components/otherlanguage/index/FightText';
import DownloadText from '../components/otherlanguage/DownloadText';
import BringText from '../components/otherlanguage/index/BringText';
import FeatureTitle from '../components/otherlanguage/index/features/FeaturesTitle';
import FeatureCheat from '../components/otherlanguage/index/features/FeatureCheat';
import FeatureSounds from '../components/otherlanguage/index/features/FeatureSounds';
import FeatureFree from '../components/otherlanguage/index/features/FeatureFree';
import FeaturePrevention from '../components/otherlanguage/index/features/FeaturePrevention';
import '../assets/sass/_masthead.scss';

if (typeof window !== "undefined") {
  window.onload = () => {
    const selectedLanguage = window.location.search.substring(1);
    if(selectedLanguage){
      localStorage.setItem("Language", selectedLanguage);
    }
  }
}

const IndexPage = () => (
  <Layout>
    <IndexHeader />

    <header className="masthead">
      <div className="container h-100">
        <div className="row h-100">
          <div className="col-lg-7 my-auto">
            <div className="header-content mx-auto">
              <FightText/>
            </div>

            <div className="header-content mx-auto">
            <Scroll type="id" element="download">
                <a href="#download" className="btn btn-color btn-xl">
                  <DownloadText />
                </a>
            </Scroll>
              </div>
          </div>
          <div className="col-lg-5 my-auto">
            <div className="device-container">
              <div className="device-mockup iphone6_plus portrait white">
                <div className="device">
                  <div className="screen">
                    <img src={demo1} className="img-fluid" alt="" />
                  </div>
                  <div className="button"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>

    <section className="download bg-primary text-center" id="download">
      <div className="container">
        <div className="row">
          <div className="col-md-8 mx-auto">
            <BringText/>
            <div className="badges">
              <a className="badge-link" href="https://play.google.com/store/apps/details?id=com.yurikanagawa.cheat_coin">
                <img src={gplay} alt="" />
              </a>
              <a className="badge-link" href="https://apps.apple.com/us/app/c-coin/id1603244437">
                <img src={astore} alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="features" id="features">
      <div className="container">
        <div className="section-heading text-center">

          <FeatureTitle/>

          <hr />
        </div>
        <div className="row">
          <div className="col-lg-4 my-auto">
            <div className="device-container">
              <div className="device-mockup iphone6_plus portrait white">
                <div className="device">
                  <div className="screen">
                    <img src={demo1} className="img-fluid" alt="" />
                  </div>
                  <div className="button"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-8 my-auto">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-6">
                  <div className="feature-item">
                    <i className="icon-screen-smartphone text-primary"></i>
                      <FeatureCheat/>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="feature-item">
                    <i className="icon-camera text-primary"></i>

                      <FeatureSounds/>

                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="feature-item">
                    <i className="icon-present text-primary"></i>

                    <FeatureFree/>

                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="feature-item">
                    <i className="icon-lock-open text-primary"></i>
                      <FeaturePrevention/>
                  </div>
                  
                </div>
                <Link className="btn btn-color btn-xl" to="/introduction">
                  <HowtouseText/>
                </Link>
                <></>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="contact bg-primary" id="contact">
      <div className="container">
        <h2>
          <ContactText/>
        </h2>
        <Social />
      </div>
    </section>
    <Footer />
  </Layout>
);

export default IndexPage;
