import React from 'react';

export default function FeaturePrevention() {
    return (
        <div >
            <h3 className = "langCng" lang="en">Prevention of fraud detection</h3>
            <p className = "langCng" lang="en">The place to tap changes under certain conditions</p>

            <h3 className = "langCng" lang="ja">不正検出の防止</h3>
            <p className = "langCng" lang="ja">タップする場所は特定の条件下で変化します</p>

            <h3 className = "langCng" lang="ru">Предотвращение обнаружения мошенничества</h3>
            <p className = "langCng" lang="ru">Где использовать изменения при определенных условиях</p>

            <h3 className = "langCng" lang="fr">Prévention de la détection des fraudes</h3>
            <p className = "langCng" lang="fr">Où exploiter les changements sous certaines conditions</p>

            <h3 className = "langCng" lang="de">Verhinderung von Betrugserkennung</h3>
            <p className = "langCng" lang="de">Wo kann man unter bestimmten Bedingungen auf Änderungen tippen</p>

            <h3 className = "langCng" lang="it">Prevenzione del rilevamento delle frodi</h3>
            <p className = "langCng" lang="it">Dove toccare le modifiche in determinate condizioni</p>

            <h3 className = "langCng" lang="zh">防止欺诈检测</h3>
            <p className = "langCng" lang="zh">在某些条件下在哪里进行更改</p>

            <h3 className = "langCng" lang="ko">부정 탐지 방지</h3>
            <p className = "langCng" lang="ko">탭 위치는 특정 조건에서 변화합니다</p>

            <h3 className = "langCng" lang="fi">Petosten havaitsemisen estäminen</h3>
            <p className = "langCng" lang="fi">Minne muuttaa muutoksia tietyissä olosuhteissa</p>

            <h3 className = "langCng" lang="es">Prevención de la detección de fraudes</h3>
            <p className = "langCng" lang="es">Dónde tocar los cambios en determinadas condiciones</p>

            <h3 className = "langCng" lang="tr">Prevención de la detección de fraudes</h3>
            <p className = "langCng" lang="tr">Dónde tocar los cambios en determinadas condiciones</p>

            <h3 className = "langCng" lang="ms">Pencegahan pengesanan penipuan</h3>
            <p className = "langCng" lang="ms">Tempat mengetik perubahan dalam keadaan tertentu</p>

            <h3 className = "langCng" lang="ta">மோசடி கண்டறிதல் தடுப்பு</h3>
            <p className = "langCng" lang="ta">சில நிபந்தனைகளின் கீழ் மாற்றங்களை எங்கு தட்ட வேண்டும்</p>

            <h3 className = "langCng" lang="id">Pencegahan deteksi penipuan</h3>
            <p className = "langCng" lang="id">Tempat untuk mengetuk berubah dalam kondisi tertentu</p>

            <h3 className = "langCng" lang="pt">Prevenção de detecção de fraudes</h3>
            <p className = "langCng" lang="pt">O local para tocar muda sob certas condições</p>

            <h3 className = "langCng" lang="hi">धोखाधड़ी का पता लगाने की रोकथाम</h3>
            <p className = "langCng" lang="hi">कुछ शर्तों के तहत परिवर्तनों को टैप करने का स्थान</p>

            <h3 className = "langCng" lang="ar">منع كشف الاحتيال</h3>
            <p className = "langCng" lang="ar">مكان النقر يتغير في ظل ظروف معينة</p>
        </div>
    );
  }