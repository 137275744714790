import React from 'react';

export default function FeatureTitle() {
    return (
        <div >
          <h2 className = "langCng" lang="en">C-Dice features</h2>
          <p className = "langCng" lang="en">Check out what you can do with this app theme</p>

          <h2 className = "langCng" lang="ja">C-Dice 機能</h2>
          <p className = "langCng" lang="ja">このアプリでできることを確認してください</p>

          <h2 className = "langCng" lang="ru">C-Dice функция</h2>
          <p className = "langCng" lang="ru">Убедитесь, что вы можете сделать это с помощью этого приложения</p>

          <h2 className = "langCng" lang="fr">C-Dice fonction</h2>
          <p className = "langCng" lang="fr">Assurez-vous que vous pouvez le faire avec cette application</p>

          <h2 className = "langCng" lang="de">C-Dice Funktion</h2>
          <p className = "langCng" lang="de">Stellen Sie sicher, dass Sie dies mit dieser App tun können</p>

          <h2 className = "langCng" lang="it">C-Dice funzione</h2>
          <p className = "langCng" lang="it">Assicurati di poterlo fare con questa app</p>

          <h2 className = "langCng" lang="zh">C-Dice 功能</h2>
          <p className = "langCng" lang="zh">确保您可以使用此应用程序执行此操作</p>

          <h2 className = "langCng" lang="ko">C-Dice 기능</h2>
          <p className = "langCng" lang="ko">이 응용 프로그램에서 할 수 있는지 확인하십시오</p>

          <h2 className = "langCng" lang="fi">C-Dice toiminto</h2>
          <p className = "langCng" lang="fi">Varmista, että voit tehdä sen tällä sovelluksella</p>

          <h2 className = "langCng" lang="es">C-Dice función</h2>
          <p className = "langCng" lang="es">Asegúrate de poder hacerlo con esta aplicación.</p>

          <h2 className = "langCng" lang="tr">C-Dice işlev</h2>
          <p className = "langCng" lang="tr">Bu uygulamayla yapabileceğinizden emin olun</p>

          <h2 className = "langCng" lang="ms">C-Dice fungsi</h2>
          <p className = "langCng" lang="ms">Pastikan anda boleh melakukannya dengan aplikasi ini</p>

          <h2 className = "langCng" lang="ta">C-Dice செயல்பாடு</h2>
          <p className = "langCng" lang="ta">இந்த பயன்பாட்டின் மூலம் நீங்கள் அதை செய்ய முடியும் என்பதை உறுதிப்படுத்தவும்</p>

          <h2 className = "langCng" lang="id">Fitur C-Dice</h2>
          <p className = "langCng" lang="id">Lihat apa yang dapat Anda lakukan dengan tema aplikasi ini</p>

          <h2 className = "langCng" lang="pt">Recursos do C-Dice</h2>
          <p className = "langCng" lang="pt">Confira o que você pode fazer com este tema de aplicativo</p>

          <h2 className = "langCng" lang="hi">सी-पासा विशेषताएं</h2>
          <p className = "langCng" lang="hi">देखें कि आप इस ऐप थीम के साथ क्या कर सकते हैं</p>

          <h2 className = "langCng" lang="ar">ميزات C-Dice</h2>
          <p className = "langCng" lang="ar">تحقق مما يمكنك القيام به باستخدام سمة التطبيق هذه</p>
        </div>
    );
  }