import React from 'react';

export default function FeatureSounds() {
    return (
        <div >
            <h3 className = "langCng" lang="en">Sounds</h3>
            <p className = "langCng" lang="en">Makes a sound when tapped</p>

            <h3 className = "langCng" lang="ja">音</h3>
            <p className = "langCng" lang="ja">タップすると音が出る</p>

            <h3 className = "langCng" lang="ru">звук</h3>
            <p className = "langCng" lang="ru">При нажатии слышен звук</p>

            <h3 className = "langCng" lang="fr">Sonner</h3>
            <p className = "langCng" lang="fr">Il y a un son lorsque vous appuyez sur</p>

            <h3 className = "langCng" lang="de">Klang</h3>
            <p className = "langCng" lang="de">Beim Tippen ertönt ein Ton</p>

            <h3 className = "langCng" lang="it">Suono</h3>
            <p className = "langCng" lang="it">Viene emesso un suono quando viene toccato</p>

            <h3 className = "langCng" lang="zh">声音</h3>
            <p className = "langCng" lang="zh">敲击时有声音</p>

            <h3 className = "langCng" lang="ko">소리</h3>
            <p className = "langCng" lang="ko">누를 때 소리가납니다.</p>

            <h3 className = "langCng" lang="fi">ääni</h3>
            <p className = "langCng" lang="fi">Napautettaessa kuuluu ääni</p>

            <h3 className = "langCng" lang="es">Sonido</h3>
            <p className = "langCng" lang="es">Hay un sonido cuando se toca</p>

            <h3 className = "langCng" lang="tr">Ses</h3>
            <p className = "langCng" lang="tr">dokunulduğunda ses geliyor</p>

            <h3 className = "langCng" lang="ms">Suara</h3>
            <p className = "langCng" lang="ms">Ada suara ketika diketuk</p>

            <h3 className = "langCng" lang="ta">ஒலி</h3>
            <p className = "langCng" lang="ta">தட்டும்போது ஒரு ஒலி எழுகிறது</p>

            <h3 className = "langCng" lang="id">suara</h3>
            <p className = "langCng" lang="id">Membuat suara saat diketuk</p>

            <h3 className = "langCng" lang="pt">Sons</h3>
            <p className = "langCng" lang="pt">Faz um som quando tocado</p>

            <h3 className = "langCng" lang="hi">ध्वनि</h3>
            <p className = "langCng" lang="hi">टैप करने पर आवाज करता है</p>

            <h3 className = "langCng" lang="ar">اصوات</h3>
            <p className = "langCng" lang="ar">يصدر صوتًا عند النقر عليه</p>

        </div>
    );
  }