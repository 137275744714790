import React from 'react';

export default function FightText() {
    return (
        <div >
            <h1 className="langCng" lang="en">Fight is not fun,<br />Victory is fun.</h1>
            <h1 className="langCng" lang="ja">戦いは楽しくない<br/>勝つことが楽しい</h1>
            <h1 className="langCng" lang="ru">Драться не весело<br/>Весело побеждать</h1>
            <h1 className="langCng" lang="fr">Se battre n'est pas amusant<br/>Amusant à gagner</h1>
            <h1 className="langCng" lang="de">Kämpfen macht keinen Spaß<br/>Spaß zu gewinnen</h1>
            <h1 className="langCng" lang="it">Combattere non è divertente<br/>Divertente da vincere</h1>
            <h1 className="langCng" lang="zh">打架不好玩<br/>赢的乐趣</h1>
            <h1 className="langCng" lang="ko">싸움은 재미 없다<br/>이길 수 재미</h1>
            <h1 className="langCng" lang="fi">Taistelu ei ole hauskaa<br/>Hauskaa voittaa</h1>
            <h1 className="langCng" lang="es">Pelear no es divertido<br/>Divertido para ganar</h1>
            <h1 className="langCng" lang="tr">Savaşmak eğlenceli değil<br/>Kazanmak için eğlenceli</h1>
            <h1 className="langCng" lang="ms">Berjuang tidak menyenangkan<br/>Seronok menang</h1>
            <h1 className="langCng" lang="ta">சண்டை வேடிக்கையாக இல்லை<br/>வெற்றி பெற வேடிக்கை</h1>

            <h1 className="langCng" lang="id">Pertarungan itu tidak menyenangkan,<br/>Kemenangan itu menyenangkan.</h1>
            <h1 className="langCng" lang="pt">A luta não é divertida,<br/>a vitória é divertida.</h1>
            <h1 className="langCng" lang="hi">लड़ाई मजेदार नहीं है, <br/>जीत मजेदार है।</h1>
            <h1 className="langCng" lang="ar">القتال ليس ممتعًا ، النصر ممتع.</h1>
        </div>
    );
  }