import React from 'react';

export default function BringText() {
    return (
        <div >
            <h2 className="langCng" lang="en">Bring victory</h2>
            <p className = "langCng" lang="en">This app is available on any mobile device</p>

            <h2 className="langCng" lang="ja">勝利をもたらす</h2>
            <p className="langCng" lang="ja">このアプリケーションはモバイルデバイスで利用可能</p>

            <h2 className="langCng" lang="ru">Принеси победу</h2>
            <p className="langCng" lang="ru">Это приложение доступно на мобильных устройствах</p>

            <h2 className="langCng" lang="fr">Apporter la victoire</h2>
            <p className = "langCng" lang="fr">Cette application est disponible sur les appareils mobiles</p>

            <h2 className="langCng" lang="de">Bring den Sieg</h2>
            <p className = "langCng" lang="de">Diese Anwendung ist auf mobilen Geräten verfügbar</p>

            <h2 className="langCng" lang="it">Porta la vittoria</h2>
            <p className = "langCng" lang="it">Questa applicazione è disponibile su dispositivi mobili</p>

            <h2 className="langCng" lang="zh">带来胜利</h2>
            <p className = "langCng" lang="zh">此应用程序可在移动设备上使用</p>

            <h2 className="langCng" lang="ko">승리를 가져다</h2>
            <p className = "langCng" lang="ko">이 응용 프로그램은 모바일 장치에서 사용할 수</p>

            <h2 className="langCng" lang="fi">Tuo voitto</h2>
            <p className = "langCng" lang="fi">Tämä sovellus on saatavilla mobiililaitteisiin</p>

            <h2 className="langCng" lang="es">Traer la victoria</h2>
            <p className = "langCng" lang="es">Esta aplicación está disponible en dispositivos móviles.</p>

            <h2 className="langCng" lang="tr">zafer getir</h2>
            <p className = "langCng" lang="tr">Bu uygulama mobil cihazlarda kullanılabilir</p>

            <h2 className="langCng" lang="ms">Bawa kemenangan</h2>
            <p className = "langCng" lang="ms">Aplikasi ini tersedia pada peranti mudah alih</p>

            <h2 className="langCng" lang="ta">வெற்றியை கொண்டு வாருங்கள்</h2>
            <p className = "langCng" lang="ta">இந்த பயன்பாடு மொபைல் சாதனங்களில் கிடைக்கிறது</p>

            <h2 className="langCng" lang="id">Membawa kemenangan</h2>
            <p className = "langCng" lang="id">Aplikasi ini tersedia di perangkat seluler apa pun</p>

            <h2 className="langCng" lang="pt">Traga a vitória</h2>
            <p className = "langCng" lang="pt">Este aplicativo está disponível em qualquer dispositivo móvel</p>

            <h2 className="langCng" lang="hi">जीत लाओ</h2>
            <p className = "langCng" lang="hi">यह ऐप किसी भी मोबाइल डिवाइस पर उपलब्ध है</p>

            <h2 className="langCng" lang="ar">حقق النصر</h2>
            <p className = "langCng" lang="ar">هذا التطبيق متاح على أي جهاز محمول</p>

        </div>
    );
  }