import React from 'react';

export default function FeaturesText() {
    return (
      <div >
        <div className = "langCng" lang="en">Features</div>
        <div className = "langCng" lang="ru">ОСОБЕННОСТИ</div>
        <div className = "langCng" lang="fr">CARACTÉRISTIQUES</div>
        <div className = "langCng" lang="de">MERKMALE</div>
        <div className = "langCng" lang="it">CARATTERISTICHE</div>
        <div className = "langCng" lang="fi">OMINAISUUDET</div>
        <div className = "langCng" lang="es">CARACTERÍSTICAS</div>
        <div className = "langCng" lang="tr">ÖZELLİKLERİ</div>
        <div className = "langCng" lang="ms">CIRI-CIRI</div>
        <div className = "langCng" lang="ta">அம்சங்கள்</div>
        <div className = "langCng" lang="id">FITUR</div>
        <div className = "langCng" lang="pt">RECURSOS</div>
        <div className = "langCng" lang="hi">विशेषताएं</div>
        <div className = "langCng" lang="ar">الميزات</div>
        <div className = "langCng" lang="ja">機能</div>
        <div className = "langCng" lang="zh">功能</div>
        <div className = "langCng" lang="ko">기능</div>
    </div>
    );
  }