import React from 'react';

export default function FeatureFree() {
    return (
        <div >
            <h3 className="langCng" lang="en">Free</h3>
            <p className="langCng" lang="en">This app is available for free</p>

            <h3 className="langCng" lang="ja">無料</h3>
            <p className="langCng" lang="ja">このアプリは無料で利用可能</p>

            <h3 className="langCng" lang="ru">бесплатно</h3>
            <p className="langCng" lang="ru">Это приложение доступно бесплатно</p>

            <h3 className="langCng" lang="fr">Libre</h3>
            <p className="langCng" lang="fr">Cette application est disponible gratuitement</p>

            <h3 className="langCng" lang="de">Kostenlos</h3>
            <p className="langCng" lang="de">Diese App ist kostenlos erhältlich</p>

            <h3 className="langCng" lang="it">Gratuito</h3>
            <p className="langCng" lang="it">Questa app è disponibile gratuitamente</p>

            <h3 className="langCng" lang="zh">自由</h3>
            <p className="langCng" lang="zh">这个应用程序是免费的</p>

            <h3 className="langCng" lang="ko">무료</h3>
            <p className="langCng" lang="ko">이 응용 프로그램은 무료로 제공</p>

            <h3 className="langCng" lang="fi">Vapaa</h3>
            <p className="langCng" lang="fi">Tämä sovellus on saatavilla ilmaiseksi</p>

            <h3 className="langCng" lang="es">Gratis</h3>
            <p className="langCng" lang="es">Esta aplicación está disponible gratis</p>

            <h3 className = "langCng" lang="tr">Bedava</h3>
            <p className = "langCng" lang="tr">Bu uygulama ücretsiz olarak kullanılabilir</p>

            <h3 className = "langCng" lang="ms">Percuma</h3>
            <p className = "langCng" lang="ms">Aplikasi ini tersedia secara percuma</p>

            <h3 className = "langCng" lang="ta">இலவசம்</h3>
            <p className = "langCng" lang="ta">இந்த ஆப் இலவசமாக கிடைக்கிறது</p>

            <h3 className = "langCng" lang="id">Bebas</h3>
            <p className = "langCng" lang="id">Aplikasi ini tersedia secara gratis</p>

            <h3 className = "langCng" lang="pt">Livre</h3>
            <p className = "langCng" lang="pt">Este aplicativo está disponível gratuitamente</p>

            <h3 className = "langCng" lang="hi">मुफ़्त</h3>
            <p className = "langCng" lang="hi">यह ऐप मुफ्त में उपलब्ध है</p>
            
            <h3 className = "langCng" lang="ar">حر</h3>
            <p className = "langCng" lang="ar">هذا التطبيق متاح مجانًا</p>
        </div>
    );
  }